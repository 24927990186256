import { CommonInfoModel } from './generic';
import { UserLegalEntity } from './legalEntity';
import { ProgressCompoundValues } from './specification';
import { CheckInStatus } from './timeBooking';

export enum ContractorProjectCategory {
  CONSTRUCTION = 'construction',
  REPAIR = 'repair',
  DEFECT = 'defect',
  RECLAMATION = 'reclamation',
  REWORK = 'rework',
  MAINTENANCE = 'maintenance',
}

export type ContractorProjectCategoryType =
  | 'construction'
  | 'repair'
  | 'defect'
  | 'reclamation'
  | 'rework'
  | 'maintenance';

export enum ContractorProjectStatus {
  FINISHED = 'finished',
  ONGOING = 'ongoing',
  IN_PREPARATION = 'inPreparation',
}

export type ContractorProjectStatusType = 'finished' | 'ongoing' | 'inPreparation';

export enum Trade {
  ARCHITECTURAL_ENGINEERING = 'architectural_engineering',
  ASPHALT = 'asphalt',
  BRICKLAYING_FACING = 'bricklaying_facing',
  BULK_MATERIALS = 'bulk_materials',
  CARPENTRY = 'carpentry',
  CONCRETE_DRILLING_CUTTING = 'concrete_drilling_cutting',
  CONCRETE_PARTS = 'concrete_parts',
  DEMOLITION = 'demolition',
  DISPOSAL_CLEANING = 'disposal_cleaning',
  DRYWALL_WORK = 'drywall_work',
  ELECTRIC_INSTALLATION = 'electric_installation',
  ETICS_PLASTERING = 'etics_plastering',
  EXCAVATION = 'excavation',
  FACADE_WORK = 'facade_work',
  FIRE_PROTECTION = 'fire_protection',
  FLOOR_COVERINGS = 'floor_coverings',
  GARDEN_LANDSCAPE = 'garden_landscape',
  HEATING_COOLING = 'heating_cooling',
  LIFTING_EQUIPMENT = 'lifting_equipment',
  LIFTS = 'lifts',
  MACHINERY_EQUIPMENT = 'machinery_equipment',
  METALWORK = 'metalwork',
  OTHER = 'other',
  PAINTING_WORK = 'painting_work',
  PAVING = 'paving',
  PLUMBING = 'plumbing',
  REINFORCING_STEEL = 'reinforcing_steel',
  ROAD_BUILDER = 'road_builder',
  ROOF_SEALING = 'roof_sealing',
  SCAFFOLDER = 'scaffolder',
  SEWER = 'sewer',
  SITE_SECURITY = 'site_security',
  STEEL_LOCKSMITH = 'steel_locksmith',
  STRUCTURAL_WORK = 'structural_work',
  TILES_PLATES_MOSAICS = 'tiles_plates_mosaics',
  VENTILATION = 'ventilation',
  WELL_BUILDER = 'well_builder',
  WINDOWS_DOORS_GATES = 'windows_doors_gates',
  WOODWORK = 'woodwork',
}

export interface ContractorProject extends ProgressCompoundValues, CommonInfoModel {
  category: ContractorProjectCategoryType;
  contractor: UserLegalEntity;
  currencySymbol: string;
  endDate: string | null;
  id: number;
  lastCheckedInAt: string | null;
  checkInStatus: CheckInStatus;
  projectConnection: ProjectConnection | null;
  specifications: number[];
  startDate: string | null;
  status: ContractorProjectStatusType;
  // Protected fields (only visible to contractors)
  city: string;
  customer: number;
  costCenter: string | null;
  costUnit: string | null;

  /**
   * Manually estimated work for the project.
   *
   * Requires the ``CONTRACTORS_VIEW_ESTIMATION`` permission.
   */
  generalProjectMinutes?: number | null;
  country: string;
  latitude: number;
  longitude: number;
  name: string;
  offerNumber: string | null;
  postalCode: string;
  projectNumber: string;
  address: string;
}

export interface ContractorProjectDetail extends ContractorProject {
  documentCount: number;
  imageCount: number;
  totalWorkMinutes: number;
  totalDayLaborWorkMinutes: number;
  teamDayLaborCount: number;
}

export interface ContractorProjectCreatePayload
  extends Pick<
    ContractorProjectDetail,
    | 'category'
    | 'city'
    | 'costCenter'
    | 'customer'
    | 'costUnit'
    | 'generalProjectMinutes'
    | 'country'
    | 'endDate'
    | 'latitude'
    | 'longitude'
    | 'name'
    | 'offerNumber'
    | 'postalCode'
    | 'projectNumber'
    | 'startDate'
    | 'status'
    | 'address'
  > {}

export interface ContractorProjectUpdatePayload extends ContractorProjectCreatePayload {
  id: ContractorProjectDetail['id'];
}

export type ContractorProjectMapData = Pick<
  ContractorProject,
  'id' | 'latitude' | 'longitude' | 'status' | 'checkInStatus'
>;

export interface ProjectConnection {
  id: number;
  name: string;
  trades: Trade[];
  contractorProjectId?: number;
  constructionProjectId: number;
  createdAt: string;
  modifiedAt: string;
}
