import type { FC } from 'react';
import React from 'react';
import { Box, LinearProgress } from '@mui/material';

import useStyles from './LoadingScreen.styles';

const LoadingScreen: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box
        sx={{
          width: 400,
        }}
      >
        <LinearProgress />
      </Box>
    </div>
  );
};

export default LoadingScreen;
