import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

/**
 * Identical to React.useEffect with the difference that it never runs on mount.
 * This is equivalent to a componentDidUpdate lifecycle function.
 *
 * @param {EffectCallback} effect - A useEffect effect.
 * @param {DependencyList} dependencies - The useEffect dependency list.
 */
const useEffectAfterMount = (effect: EffectCallback, dependencies?: DependencyList): void => {
  const wasRendered = useRef(false);

  useEffect(() => {
    if (wasRendered.current === false) {
      wasRendered.current = true;
      return;
    }

    const result = effect();
    if (result && typeof result === 'function') {
      return result;
    }
  }, dependencies);

  // Reset for the next mount.
  useEffect(() => {
    return () => {
      wasRendered.current = false;
    };
  }, []);
};

export default useEffectAfterMount;
