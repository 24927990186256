import type { FC } from 'react';
import React from 'react';
import { Menu as MenuIcon } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, IconButton, SvgIcon, Toolbar } from '@mui/material';
import clsx from 'clsx';

import Logo from 'src/components/Logo/Logo';

import Notifications from './Notifications/Notifications';
import TopBarMenu from './TopBarMenu/TopBarMenu';
import useStyles from './TopBar.styles';

interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
}

const TopBar: FC<TopBarProps> = ({ className, onMobileNavOpen = (): void => {}, ...props }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} {...props}>
      <Toolbar className={classes.toolbar}>
        <Box sx={{ display: { lg: 'none', xs: 'block' } }}>
          <IconButton color="primary" onClick={onMobileNavOpen} size="large">
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Box>

        <Box sx={{ display: { lg: 'block', xs: 'none' } }}>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Box>

        <Box
          sx={{
            ml: 2,
            flexGrow: 1,
          }}
        />

        <Notifications />

        <Box
          sx={{
            ml: 2,
          }}
        >
          <TopBarMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
