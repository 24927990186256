import type { FC } from 'react';
import React, { useRef, useState } from 'react';
import { Bell as BellIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Popover, SvgIcon, Tooltip, Typography } from '@mui/material';

import useStyles from './Notifications.styles';

const Notifications: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={t('Notifications.notifications')}>
        <IconButton color="primary" ref={ref} onClick={handleOpen} size="large">
          <SvgIcon>
            <BellIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box
          sx={{
            p: 2,
          }}
        >
          <Typography variant="h4" color="textPrimary">
            {t('Notifications.notifications')}
          </Typography>
        </Box>

        <Box
          sx={{
            p: 2,
          }}
        >
          <Typography variant="h6" color="textPrimary">
            {t('Notifications.empty')}
          </Typography>
        </Box>
      </Popover>
    </>
  );
};

export default Notifications;
